<template>
	<div id="wrap">
		<top page="personalOrder" @search="handleSearch" :showSearchButton="true" />
		<div class="mobile-wrap">
			<div class="mail mainBox">
				<div class="left-box">
					<div class="user">
						<div class="user-title">
							<span class="vip">{{grade === 'normal' ? '普通' : 'VIP'}}</span>
							<span>{{phoneNumbers}}</span>
							<a @click="handleLogout">退出</a>
						</div>
					</div>
					<div class="side-menu">
						<router-link to="/personal/order" class="on">我的订单</router-link>
						<router-link to="/personal/video" v-if="this.vipRoles.indexOf('视频') >= 0">视频</router-link>
						<router-link to="/personal/audio" v-if="this.vipRoles.indexOf('音频') >= 0">音频</router-link>
						<router-link to="/personal/document" v-if="this.vipRoles.indexOf('文档下载') >= 0">文档</router-link>
					</div>
				</div>
				<!-- left-box end -->
				<div class="right-box">
					<!-- 暂无内容 -->
					<div class="no-page" v-if="prepayList.length == 0">
						暂无内容
					</div>
					<!-- 订单列表 -->
					<div class="list-box-01" v-else>
						<div v-for="item in prepayList" :key="item.id" :class="item.checkStatus === 'uncheck' ? 'list not' : 'list'">
							<div class="pic">
								<img :src="item.activity.cover.absolutePath"  />
							</div>
							<div class="con">
								<h1>{{item.activity.name}}</h1>
								<div class="list-info">
									<div class="time">{{item.activity.displayTime}}</div>
									<div class="adds">{{item.spec.name}}｜{{item.activity.location}}</div>
								</div>
								<div class="price">¥{{item.price}}<span> /共1张</span></div>
							</div>
						</div>
					</div>
				</div>
				<!-- right-box end -->
				<div class="clear"></div>
			</div>
		</div>
		<bottom />
		<side />
	</div>
</template>

<script>
import top from "../components/top.vue";
import bottom from "../components/bottom.vue";
import side from "../components/side.vue";
import { mapGetters, mapActions } from "vuex";
import { wxShare } from "@/utils/share.js";
export default {
	page: {
		title: '用户中心-我的订单'
	},
	data() {
		return {
			prepayList: [],
			search: {
				activityName: ''
			}
		}
	},
	computed: {
		...mapGetters(["grade", "phoneNumbers", "vipRoles"])
	},
	components: {
		top,
		bottom,
		side
	},
	methods: {
		...mapActions(["auth/logout"]),
		handleWxShare() {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: 'C-FLROAL线下活动报名', // 分享标题
                        desc: '名师全视频干货解析，当下全球最新流行的花艺技法与造型',
                        link: 'http://www.c-floral.net/#/about',
                        imgUrl: 'http://www.c-floral.net/images/share/logo-02.jpg' // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleLogout() {
			this["auth/logout"]()
			.then(() => {
				location.reload();
			})
		},
		handleFindAllPrepay() {
			this.$api.auth.user.findAllPrepay(this.search.activityName)
			.then(response => {
				if(response.code === '0') {
					this.prepayList = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleSearch(keywords) {
			this.search.activityName = keywords;
			this.handleFindAllPrepay()
		}
	},
	mounted() {
		this.handleWxShare()
		this.handleFindAllPrepay()
	}
}
</script>

<style src="../../style/css/my-center.css" scoped></style>
<style scoped>

</style>